@use "../../styles/variables" as v;

.search-container {

  .display-found {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
      position: sticky;
      top: 50px;
      z-index: 10;

      .wrapper {
        background-color: white;
        box-shadow: 0 0 10px 2px rgba(v.$color2, 0.3), 0 0 5px 1px rgba(v.$color2, 0.4);
      }
    }

    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 10px;
      border: 1px solid v.$color1;
      border-radius: 10px;
      overflow: hidden;
      font-size: 14px;

      .found-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        padding: 0 10px;
      }
    }
  }

  .tr-datatable-wrapper {
    .card {
      border: none;
      border-radius: 10px;
      overflow: hidden;

      table {
        border-collapse: separate;
        border-spacing: 0 10px;

        thead tr {
          font-size: 16px;

          th {
            padding-bottom: 10px;
            background: none;

            &:first-child {
              padding-left: 5rem;
            }

            padding-bottom: 20px !important;

            &:not(:first-child) {
              .p-column-header-content {
                display: flex;
                justify-content: center;
              }
            }
          }
        }

        tbody tr {
          font-size: 14px;

          &:nth-child(even) {
            td {
              background-color: v.$color6;
            }
          }

          &:nth-child(odd) {
            td {
              background-color: v.$color12;
            }
          }

          td {
            padding: 6px 0;

            &:first-child {
              border-radius: 10px 10px 0 0;
            }

            &:last-child {
              border-radius: 0 0 10px 10px;
            }

            @media screen and (min-width: 768px) {
              padding: 10px 0;

              &:first-child {
                border-radius: 10px 0 0 10px;
              }

              &:last-child {
                border-radius: 0 10px 10px 0;
              }

              &:not(:first-child) {
                div {
                  display: flex;
                  justify-content: center !important;
                  align-items: center;
                }
              }
            }

            .operationsButton {
              display: flex;
              gap: 5px;

              .btn-link {
                color: white;
                background-color: v.$color8;
                border: 1px solid v.$color8;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border-radius: 50%;

                &.fav-button {
                  &.faved {
                    color: v.$color-white;
                    background-color: v.$color-error-light;
                    border: 1px solid v.$color-error-dark;

                    .heart-icon {
                      animation: pulse 0.6s;
                    }

                    @keyframes pulse {
                      0% {
                        transform: scale(0.7);
                      }

                      50% {
                        transform: scale(1.3);
                      }

                      100% {
                        transform: scale(1);
                      }
                    }
                  }
                }

                &.details-button {
                  &:hover {
                    border-color: v.$color-info-dark;
                    background-color: v.$color-info-light;
                  }
                }
              }

              svg {
                height: 16px;
                width: 16px;
              }
            }
          }
        }
      }
    }
  }

  .getproperty {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .image {
      border-radius: 10px;
      overflow: hidden;

      .ad-thumbnail {
        height: 200px;
        width: 100%;

        img {
          height: 200px;
          width: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }

    @media screen and (min-width: 768px) {
      flex-direction: row;

      .image {

        .ad-thumbnail {
          height: 100px;
          width: 150px;

          img {
            height: 100px;
            width: 150px;
          }
        }
      }
    }
  }
}